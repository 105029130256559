import { Component, Vue, Prop } from 'vue-property-decorator'
import { Content } from '@/Models'
import { ValidationObserver } from 'vee-validate'
@Component({
  components: {
    ValidationObserver,
  },
})
export default class Quadrant extends Vue {
  @Prop({ required: true })
  content!: Content[]
  @Prop({ required: true })
  index!: number

  delete_item() {
    this.$emit('deleteInput', { index: this.index })
  }

  update_item() {
    this.$emit('updateContent', { index: this.index })
  }
}
